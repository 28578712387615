import styled from '@emotion/styled';
import {IAccessCodeProps} from './AccessCodeDefinition';

type AccessCodeStyleProps = Partial<IAccessCodeProps>;

export const Container = styled.div<AccessCodeStyleProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Title = styled.h1<AccessCodeStyleProps>`
  color: ${({titleColor}) => titleColor};
  margin: 0;
  font-size: 4.375rem;
  font-weight: 300;
`;
export const Subtitle = styled.h3<AccessCodeStyleProps>`
  color: ${({subtitleColor}) => subtitleColor};
  white-space: pre-wrap;
  margin: 0;
  font-size: 0.875rem;
`;
export const Form = styled.form<AccessCodeStyleProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  > * {
    margin: 0.75rem;
  }
`;
export const Checkbox = styled.input<AccessCodeStyleProps>`
  margin: 0.25rem;
`;
export const CheckboxLabel = styled.label<AccessCodeStyleProps>`
  > a {
    margin-left: 0.25rem;
  }
`;

export const CheckboxInputGroup = styled.div<AccessCodeStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const CodeInputGroup = styled.div<AccessCodeStyleProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: space-between;

  border-radius: 3.125rem;
  border: 1px solid grey;
  background-color: white;

  position: relative;

  height: 100%;
  width: 100%;
`;

export const codeTextInputStyle = `
  margin: 3px;
  outline: none;
  box-shadow: none;
  border-style: none;
  border: none;
  &:focus {
    box-shadow: none;
    outline: none;
  }
  text-transform: uppercase;
`;

export const ErrorMessage = styled.div`
  margin: 5px;
  font-size: 0.75rem;
  color: red;
  text-align: center;
`;

export const SuccessMessage = styled.div`
  margin: 5px;
  font-size: 0.75rem;
  text-align: center;
`;
