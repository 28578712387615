import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {AccessCodeComponent} from './AccessCodeComponent';
import {
  ComponentDefinition,
  type AccessCodeComponentDefinition,
} from './AccessCodeDefinition';

type Kind = AccessCodeComponentDefinition['component'];

type Props = AccessCodeComponentDefinition['props'];

export const AccessCodeModule: Module<Kind, Props> = {
  isContent(value): value is AccessCodeComponentDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  isLayout(value): value is AccessCodeComponentDefinition {
    return false;
  },
  tryRender(definition) {
    if (AccessCodeModule.isContent(definition)) {
      return React.createElement(AccessCodeComponent, definition);
    } else {
      return NextModule;
    }
  },
};
