/// <reference types="@emotion/react/types/css-prop" />
import {
  useAnimationStates,
  useShowInstructions,
} from '@backstage-components/base';
import {css} from '@emotion/react';
import {motion} from 'framer-motion';
import {type FC, type PropsWithChildren} from 'react';
import {Container as StyledContainer} from './AccessCode.styled';
import {
  ComponentDefinition,
  type AccessCodeComponentDefinition,
} from './AccessCodeDefinition';

const MotionContainer = motion(StyledContainer);

interface ContainerProps {
  className: string;
  definition: AccessCodeComponentDefinition;
  id: string;
}

/**
 * Manages `animationStates` around the `children` given to this component.
 */
export const AccessCodeContainer: FC<PropsWithChildren<ContainerProps>> = ({
  children,
  className,
  definition,
  id,
}) => {
  const {props} = definition;
  const {animationStates = []} = props;

  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;

  const {observable, broadcast} = useShowInstructions(
    ComponentDefinition.instructions,
    definition
  );
  const motionOptions = useAnimationStates(
    observable,
    broadcast,
    animationStates
  );

  return (
    <MotionContainer
      id={id}
      className={className}
      css={styles}
      {...motionOptions}
    >
      {children}
    </MotionContainer>
  );
};
