export {AccessCodeComponent} from './AccessCodeComponent';
export {
  AccessCodeInstructionSchema,
  ComponentDefinition,
} from './AccessCodeDefinition';
export type {
  AccessCodeComponentDefinition,
  SchemaType,
} from './AccessCodeDefinition';
export {AccessCodePreviewComponent} from './AccessCodePreviewComponent';

import {Registry} from '@backstage-components/base';
import {
  type AccessCodeSuccessEvent,
  AccessCodeSuccessEventName,
} from './AccessCodeMachine';
import {AccessCodeModule} from './AccessCodeModule';
import {ComponentDefinition} from './AccessCodeDefinition';
Registry.register(ComponentDefinition, AccessCodeModule);

declare global {
  // Extends `#addEventListener` and `#removeEventListener` type definition to
  // include an overload for the `AccessCodeSuccessEventName` custom event
  interface HTMLElement {
    addEventListener(
      type: typeof AccessCodeSuccessEventName,
      listener: (this: HTMLElement, ev: AccessCodeSuccessEvent) => void,
      options?: boolean | AddEventListenerOptions
    ): void;
    removeEventListener(
      type: typeof AccessCodeSuccessEventName,
      listener: (this: HTMLElement, ev: AccessCodeSuccessEvent) => void,
      options?: boolean | EventListenerOptions
    ): void;
  }
}
