import type {BroadcastFn} from '@backstage-components/base';
import {assertEvent, assign, setup} from 'xstate';
import {
  AccessCodeInstruction,
  AccessCodeInstructionSchema,
} from './AccessCodeDefinition';

export const AccessCodeMachine = setup({
  types: {} as {
    context: Context;
    events: AccessCodeInstruction;
    input: Pick<Context, 'broadcast' | 'showId'>;
  },
  actions: {
    broadcastFailure: ({context, event}) => {
      assertEvent(event, 'AccessCode:failure');
      context.broadcast({
        type: 'AccessCode:on-failure',
        meta: {
          showId: context.showId,
          reason: event.meta.reason,
        },
      });
    },
    broadcastSuccess: ({context, event}) => {
      assertEvent(event, 'AccessCode:success');
      if (typeof window === 'object') {
        const e: AccessCodeSuccessEvent = new CustomEvent(
          AccessCodeSuccessEventName,
          {detail: {attendee: event.meta.attendee, showId: context.showId}}
        );
        document.body.dispatchEvent(e);
      }
    },
    updateAttendee: assign(({context, event}): SuccessContext => {
      assertEvent(event, 'AccessCode:success');
      const attendee = event.meta.attendee;
      return {
        broadcast: context.broadcast,
        showId: context.showId,
        attendee,
      };
    }),
    updateReason: assign(({context, event}): FailureContext => {
      assertEvent(event, 'AccessCode:failure');
      return {
        broadcast: context.broadcast,
        showId: context.showId,
        reason: event.meta.reason,
      };
    }),
  },
}).createMachine({
  id: 'AccessCode',
  initial: 'idle',
  context: ({input}) => ({broadcast: input.broadcast, showId: input.showId}),
  states: {
    idle: {
      on: {
        'AccessCode:verify': {target: 'pending'},
      },
    },
    pending: {
      on: {
        'AccessCode:success': {
          target: 'success',
          actions: ['updateAttendee'],
        },
        'AccessCode:failure': {
          target: 'failure',
          actions: ['updateReason'],
        },
      },
    },
    success: {
      entry: ['broadcastSuccess'],
      on: {
        'AccessCode:verify': {target: 'pending'},
      },
    },
    failure: {
      entry: ['broadcastFailure'],
      on: {
        'AccessCode:reset': 'idle',
        'AccessCode:verify': {target: 'pending'},
      },
    },
  },
});

interface BaseContext {
  broadcast: BroadcastFn<typeof AccessCodeInstructionSchema>;
  showId: string;
}

interface FailureContext extends BaseContext {
  reason?: string;
}

interface SuccessContext extends BaseContext {
  attendee: Attendee;
}

export type Context = BaseContext | FailureContext | SuccessContext;

interface Attendee {
  id: string;
  email: string | null;
  name: string;
  chatTokens: ChatToken[];
  tags: string[];
}

interface ChatToken {
  token: string;
}

export const AccessCodeSuccessEventName = 'AccessCode:success';

export type AccessCodeSuccessEvent = CustomEvent<
  Pick<SuccessContext, 'attendee' | 'showId'>
>;
