import {gql} from '@apollo/client';

export const ResendAccessCodeMutation = gql`
  mutation ResendAccessCode($data: EmailCreateInput!) {
    triggerResendAccessCode(data: $data) {
      ... on EmailTrigger {
        id
      }
      ... on DataIntegrityError {
        code
        message
        type
      }
    }
  }
`;
